import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";

import SEOImage from "../images/seo-images/services.png";

import { SiJamstack } from "react-icons/si";
import { FaPalette, FaFileCode, FaHeadset } from "react-icons/fa";

import "../styles/services.scss";

const Services = () => {
  return (
    <Layout>
      <Helmet>
        <title>
          Services | Branch Out Online | Website Design & Development
        </title>
        <meta
          name="description"
          content="From design to development, we offer affordable and personalised web services."
        />
        <meta property="og:image" content={SEOImage} />
      </Helmet>
      <section className="hero-section">
        <div className="hero-heading">
          <h1>Website Design & Development</h1>
          <StaticImage
            src="../images/services-img.png"
            alt="Image of code snippets with a Figma design template"
            placeholder="blurred"
            className="hero-heading-img"
          />
          <div className="hero-txt">
            <p>
              Like the sound of affordable, personalised and easy to understand
              web services? Then you are in the right place! Take a look below
              at some of the services we offer...
            </p>
          </div>
        </div>
      </section>
      <section className="services-second-container">
        <h2>What we offer</h2>
        <div className="services-container">
          <div className="individual-service service-one">
            <div className="service-icon">
              <FaPalette />
            </div>
            <h3>Design & Development</h3>
            <p>
              Our pixel perfect precision will ensure you have a site you are
              proud of and one that customers will enjoy using. We ensure a
              consistent look and feel throughout your website and write code
              that enables functionality across the board.
            </p>
          </div>
          <div className="individual-service service-two">
            <div className="service-icon">
              <SiJamstack />
            </div>
            <h3>Jamstack Development</h3>
            <p>
              Using Jamstack development, we are able to build incredibly fast,
              secure and aesthetically pleasing websites. Utilising the Gatsby
              framework your site is built using modern technology, meaning
              customers experience the very best of your site...all day, every
              day.
            </p>
          </div>
          <div className="individual-service service-three">
            <div className="service-icon">
              <FaFileCode />
            </div>
            <h3>Customised CMS</h3>
            <p>
              Want the ability to edit your website content when you want? We've
              got you covered.
            </p>
            <p>
              We can create a customised content management system to fit your
              specific needs. This means editing a heading, a feature image or
              updating your contact details is easier than ever!
            </p>
          </div>
          <div className="individual-service service-four">
            <div className="service-icon">
              <FaHeadset />
            </div>
            <h3>Support</h3>
            <p>
              When working with us, you won't feel alone. Our support is 'part
              of the package' and is something we pride ourselves on providing.
            </p>
          </div>
          <div className="individual-service">
            <Link to="/contact" className="ser-btn">
              <h3>Ready to branch out online?</h3>
              <p>Get in touch today!</p>
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Services;
